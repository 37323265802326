import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import FreelanceLayout from "layouts/freelance"
import EnterpriseLayout from "layouts/entreprise"
import Page404 from 'views/404/404'
const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="freelance/*" element={<FreelanceLayout />} />
      <Route path="enterprise/*" element={<EnterpriseLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="404" element={<Page404 />} />
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="/*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default App;
