import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Enterprise Imports
import EnterpriseDashboard from "views/enterprise/acceuil";
import EnterpriseFreelance from "views/enterprise/freelance";
import EnterpriseBlog from "views/enterprise/blog";
import Enterpriserofile from "views/enterprise/profile";
import EnterpriseNotif from "views/enterprise/notifications";
import EDataTables from "views/enterprise/tables";

//Freelance Imports
import FreelanceDashboard from "views/freelance/acceuil";
import FreelanceBlog from "views/freelance/blog";
import FreelanceProfile from "views/freelance/profile";

// Auth Imports

// Icon Imports
import {
  MdDashboard,
  MdArticle,
  MdBarChart,
  MdPerson,
  MdPeople,
  MdAccountCircle,
  MdBusiness,
  MdAddAlert
} from "react-icons/md";
import { BsChat } from "react-icons/bs";

const routes = [
  {
    name: "Tableau de Bord",
    layout: "/admin",
    path: "accueil",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Blog",
    layout: "/admin",
    path: "blog",
    icon: <MdArticle className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Données",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "donnees",
    component: <DataTables />,
  },
  {
    name: "Compte",
    layout: "/admin",
    path: "account",
    icon: <MdAccountCircle className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Tableau de Bord",
    layout: "/enterprise",
    path: "accueil",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <EnterpriseDashboard />,
  },
  {
    name: "Freelances",
    layout: "/enterprise",
    path: "freelances",
    icon: <MdPeople className="h-6 w-6" />,
    component: <EnterpriseFreelance />,
    secondary: true,
  },
  {
    name: "Discussions",
    layout: "/enterprise",
    path: "chat",
    icon: <BsChat className="h-6 w-6" />,
    component: <EnterpriseFreelance />,
    secondary: true,
  },
  {
    name: "Blog",
    layout: "/enterprise",
    path: "blog",
    icon: <MdArticle className="h-6 w-6" />,
    component: <EnterpriseBlog />,
    secondary: true,
  },
  {
    name: "Données",
    layout: "/enterprise",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "données",
    component: <EDataTables />,
  },
  {
    name: "Notifications",
    layout: "/enterprise",
    icon: <MdAddAlert className="h-6 w-6" />,
    path: "notifications",
    component: <EnterpriseNotif />,
  },
  {
    name: "Compte",
    layout: "/enterprise",
    path: "account",
    icon: <MdAccountCircle className="h-6 w-6" />,
    component: <Enterpriserofile />,
  },
  {
    name: "Tableau de Bord",
    layout: "/freelance",
    path: "accueil",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <FreelanceDashboard />,
  },
  {
    name: "Discussions",
    layout: "/freelance",
    path: "chat",
    icon: <BsChat className="h-6 w-6" />,
    component: <FreelanceBlog />,
    secondary: true,
  },
  {
    name: "enterprises",
    layout: "/freelance",
    path: "enterprises",
    icon: <MdBusiness className="h-6 w-6" />,
    component: <FreelanceBlog />,
    secondary: true,
  },
  {
    name: "Blog",
    layout: "/freelance",
    path: "blog",
    icon: <MdArticle className="h-6 w-6" />,
    component: <FreelanceBlog />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/freelance",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <FreelanceProfile />,
  },/* 
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
  }, */
];
export default routes;
