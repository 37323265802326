import { useState } from "react";
import { DATA } from "./store/mockData";
import { AiFillEye } from "react-icons/ai";
import { ImageContainer } from "./components/ImageContainer";

const Notifications = () => {
    const [accordion, setActiveAccordion] = useState(-1);

    function toggleAccordion(index) {
        if (index === accordion) {
            setActiveAccordion(-1);
            return;
        }
        setActiveAccordion(index);
    }

    return (
        <div className="m-auto max-w-[1140px] bg-white my-8">
            <div className="accordion_faq">
                {DATA.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => toggleAccordion(index)}
                        className="bg-white rounded-lg shadow-md mb-4"
                    >
                        <div className="accordion_faq-heading cursor-pointer flex justify-between items-center p-5 mb-4 font-poppins">
                            <div className="flex ">
                                <ImageContainer
                                    src={item.userDpUrl}
                                    alt={item.userName}
                                    className="my-2"
                                />
                                <h3
                                    className={
                                        accordion === index ? "text-primary " : "text-black pl-1"
                                    }
                                >
                                    {item.userName}
                                </h3>
                                <h3
                                    className={
                                        accordion === index ? "text-primary " : "text-black pl-1"
                                    }
                                >
                                    {item.content}
                                </h3>
                            </div>

                            <div>
                                {accordion === index ? (
                                    <span className="text-black font-poppins font-semibold">
                                        x
                                    </span>
                                ) : (
                                    <span className="text-black font-poppins font-semibold">
                                        <AiFillEye />
                                    </span>
                                )}
                            </div>
                        </div>
                        {accordion === index && (
                            <div className="p-4 text-gray-600 font-poppins">
                                {item.answer}
                                {item.hasOptions ? (
                                    <div className="p-4 text-gray-600 font-poppins">
                                        <ul className="decoration-black list-disc">
                                            {item.options.map((opt, index) => (
                                                <li key={index}>{opt.option}</li>
                                            ))}{" "}
                                        </ul>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Notifications;