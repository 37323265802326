/* eslint-disable no-useless-escape */
import toast from "react-hot-toast"

/** Login page validation */
export async function loginValidation(values) {
    const errors = emailPasswordVerification({}, values);

    return errors;
}

/** register page validation */
export async function RegisterValidation(values) {
    const errors = RegisterVerification({}, values);

    return errors;
}




/** Email and password validation */
function emailPasswordVerification(errors = {}, values) {

    // eslint-disable-next-line no-useless-escape
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!values.password) {
        errors.password = toast.error("Vous devez entrer le mot de passe");
    } else if (values.password.includes(" ")) {
        errors.password = toast.error("Mot de passe invalid");
    } else if (values.password.length < 8) {
        errors.password = toast.error("Le mot de passe doit etre minimum 8 caractères");
    } else if (specialChars.test(values.password)) {
        errors.password = toast.error("Caractères invalid dans votre mot de passe");
    }

    if (!values.email) {
        errors.email = toast.error("Vous devez entre votre email!");
    } else if (values.email.includes(" ")) {
        errors.email = toast.error("Email Incorrect...!")
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = toast.error("Email Invalid...!")
    }
    return errors;
}


/**password validation */
function RegisterVerification(errors = {}, values) {
    // eslint-disable-next-line no-useless-escape
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (!values.password) {
        errors.password = toast.error("Vous devez entrer le mot de passe");
    } else if (values.password.includes(" ")) {
        errors.password = toast.error("Mot de passe invalid");
    } else if (values.password.length < 8) {
        errors.password = toast.error("Le mot de passe doit etre minimum 8 caractères");
    } else if (specialChars.test(values.password)) {
        errors.password = toast.error("Caractères invalid dans votre mot de passe");
    }

    if (!values.email) {
        errors.email = toast.error("Vous devez entre votre email!");
    } else if (values.email.includes(" ")) {
        errors.email = toast.error("Email Incorrect...!")
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = toast.error("Email Invalid...!")
    }

    if (!values.username) {
        errors.username = toast.error("Vous devez entrer votre nom");
    } else if (specialChars.test(values.username)) {
        errors.username = toast.error("Nom invalid")
    }
    return errors;
}

/**password validation */
function passwordVerification(errors = {}, values) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!values.password) {
        errors.password = toast.error("Vous devez entrer le mot de passe");
    } else if (values.username.includes("")) {
        errors.username = toast.error("Mot de passe invalid");
    } else if (values.password.length < 8) {
        errors.username = toast.error("Le mot de passe doit etre minimum 8 caractères");
    } else if (!specialChars.test(values.password)) {
        errors.username = toast.error("Caractères invalid dans votre mot de passe");
    }
    return errors;
}
