import React from "react";
import { useNavigate } from "react-router";
import I404 from './../../assets/img/auth/404.png'

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white w-full overflow-hidden font-poppins py-8">
      <div className="">
        <div className="w-9/12 m-auto lg:py-2 min-h-screen flex items-center justify-center">
          <div className="bg-white  overflow-hidden sm:rounded-lg pb-8">
            <div className=" text-center items-center">
              <img src={I404} alt="404" className="ml-8 w-[950px]" />
              <h1 className="text-4xl lg:text-6xl font-medium py-8">
                oops! Page introuvable
              </h1>
              <p className="text-xl lg:text-2xl pb-8 px-12 font-medium">
                Oops! La page que vous rechercher n'existe pas. Elle a peut-être
                été déplacé ou supprimé.
              </p>
              <button
                onClick={() => navigate("/")}
                className="bg-gradient-to-r from-blue-800 to-green-700 hover:from-green-700 hover:to-blue-800 text-white font-semibold px-6 py-3 rounded-md mr-6"
              >
                Accueil
              </button>
              <button
                onClick={() => navigate('/admin/blog')}
                className="bg-gradient-to-r from-blue-800 to-green-700 hover:from-green-700 hover:to-blue-800 text-white font-semibold px-6 py-3 rounded-md"
              >
                Contacter nous
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
