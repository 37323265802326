/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import NFt3 from "assets/img/nfts/Nft3.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";

import FreelanceCard from "components/card/FreelanceCard";
import { axiosGet } from "helper/axiosServices";

const Freelance = () => {
  const [freelances, setFreelances] = useState([]);

  const getFreelance = async () => {
    try {
      const res = await axiosGet('/getFreelances');
      setFreelances(res.data);
      console.log(res.data)
      console.log(freelances)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getFreelance();
  }, []);
  return (
    <div className="mt-3 grid h-fu   ll grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        <Banner />

        {/* NFt Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Freelances Recommandés
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Dev
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Marketing
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Community management
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                <a href=" ">Infographie</a>
              </a>
            </li>
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-4">
          {freelances.map(
            (freelance, key) => {
              <FreelanceCard
                bidders={[avatar1, avatar2, avatar3]}
                title={freelance.username}
                author="Esthera Jackson"
                price="0.91"
                image={NFt3}
              />
            })}
        </div>
        {/* Recenlty Added section */}
        <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Recently Added
          </h4>
        </div>

        {/* Nouveaux Freelances */}
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">


        </div>
      </div>
    </div>
  );
};

export default Freelance;
