import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "components/checkbox";
import { toast, Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { RegisterValidation } from '../../helper/validate.js'
import { useState } from "react";
import { registerUser, googleSignup } from '../../helper/helper'

export default function SignUp() {
    const navigate = useNavigate();
    const [news, setNews] = useState(false);
    const [terms, setTerms] = useState(false);

    const googleAuth =() =>{
        googleSignup();
        console.log('clicked')
    }
    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            profile: ''
        },
        validate: RegisterValidation,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async values => {
            let register = registerUser(values);
            toast.promise(register, {
                loading: 'Operation en cours..',
                success: <b>Compte crée avec sucess</b>,
                error: <b>Erreur lors de la creation</b>
            });
            register.then(function () { navigate('/') });
        }
    });

    return (
        <div className="mt-2 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <Toaster position="top-center" reverseOrder={false}></Toaster>
            <div className="mt-[4vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Rejoignez-nous aujourd'hui
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Entrez votre email et votre mot de passe pour vous inscrire.
                </p>
                <div onClick={googleAuth} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                    <div className="rounded-full text-xl">
                        <FcGoogle />
                    </div>
                    <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                        Se connecter avec Google
                    </h5>
                </div>
                <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                    <div className="rounded-full text-xl">
                        <BsLinkedin color="blue" />
                    </div>
                    <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                        Se connecter avec LinkedIn
                    </h5>
                </div>
                <div className="mb-6 flex items-center  gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    <p className="text-base text-gray-600 dark:text-white"> ou </p>
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                </div>
                {/* Nom */}
                <div className="mb-3">
                    <label
                        htmlFor="name"
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                    >
                        Nom*
                    </label>
                    <input
                        {...formik.getFieldProps('username')}
                        type="text"
                        id="username"
                        placeholder="Jean"
                        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${false === true
                            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                            : "state" === "error"
                                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                                : "state" === "success"
                                    ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                    : "border-gray-200 dark:!border-white/10 dark:text-white"
                            }`}
                    />
                </div>

                {/* Email */}
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                    >
                        Email*
                    </label>
                    <input
                        {...formik.getFieldProps('email')}
                        type="text"
                        id="email"
                        placeholder="example@domaine.com"
                        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${false === true
                            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                            : "state" === "error"
                                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                                : "state" === "success"
                                    ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                    : "border-gray-200 dark:!border-white/10 dark:text-white"
                            }`}
                    />
                </div>

                {/* Password */}
                <div className="mb-3">
                    <label
                        htmlFor="password"
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                    >
                        Mot de Passe*
                    </label>
                    <input
                        {...formik.getFieldProps('password')}
                        type="password"
                        id="password"
                        placeholder="********"
                        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${false === true
                            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                            : "state" === "error"
                                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                                : "state" === "success"
                                    ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                    : "border-gray-200 dark:!border-white/10 dark:text-white"
                            }`}
                    />
                </div>
                {/* Checkbox */}
                <div className="mb-4 flex items-center justify-between px-2">
                    <div className="flex items-center">
                        <Checkbox checked={terms} />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            J'accepte les &nbsp;
                            <a
                                target="_blank"
                                href="https://afreelinks.com/Conditions%20d'utilisation.html"
                                className="font-normal text-black transition-colors hover:text-gray-900 underline" rel="noreferrer"
                            >
                                termes et conditions
                            </a>
                        </p>
                    </div>

                </div>
                <div className="mb-4 flex items-center justify-between px-2">
                    <div className="flex items-center">
                        <Checkbox checked={news} />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Souscrire à la newsletter
                        </p>
                    </div>

                </div>
                <button type="submit" onClick={formik.handleSubmit} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Rejoindre
                </button>
                <div className="mt-4">
                    <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                        Vous avez déjà un compte?
                    </span>
                    <Link to="/auth/sign-in" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">Se connecter</Link>

                </div>
            </div>
        </div>
    );
}
