import MiniCalendar from "components/calendar/MiniCalendar";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { IoDocuments, IoLink, IoEyeOutline, IoBusiness } from "react-icons/io5";
import { MdLinkOff} from "react-icons/md";


import Widget from "components/widget/Widget";
import DailyTraffic from "views/admin/default/components/DailyTraffic";

import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";

import NftCard from "components/card/NftCard";

const FreelanceDashboard = () => {
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-4">
        <Widget
          icon={<IoEyeOutline className="h-7 w-7" />}
          title={"Vues du profil"}
          subtitle={"100"}
        />
        <Widget
          icon={<IoBusiness className="h-6 w-6" />}
          title={"Entreprises"}
          subtitle={"20"}
        />
        <Widget
          icon={<MdLinkOff className="h-7 w-7" />}
          title={"Demandes de Link"}
          subtitle={"150"}
        />
        <Widget
          icon={<IoLink className="h-6 w-6" />}
          title={"Link reçus"}
          subtitle={"3"}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <TotalSpent />
        <DailyTraffic />
        <MiniCalendar />
      </div>
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">


        {/* Blog Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Articles Tendances
          </h4>

        </div>

        {/* Aricles trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt3}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="ETH AI Brain"
            author="Nick Wilson"
            price="0.7"
            image={NFt2}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Mesh Gradients"
            author="Will Smith"
            price="2.91"
            image={NFt4}
          />
        </div>

        {/* Recenlty Added setion */}
        <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Ajouté recement
          </h4>

        </div>

        {/* Recently Add NFTs */}
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt4}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="ETH AI Brain"
            author="Nick Wilson"
            price="0.7"
            image={NFt5}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Mesh Gradients"
            author="Will Smith"
            price="2.91"
            image={NFt6}
          />
        </div>
      </div>
    </div>
  );
};

export default FreelanceDashboard;
